<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">暢遊福岡必備的景點套票，精選多個人氣設施，交通套票與美食店家，一週內可以任選三個喜歡的設施，出示電子票券QR code就可以輕鬆入場體驗。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同設施只可以使用一次，相同設施不可再入場<br>
          ●票劵使用效期為購買日起<span>90天內</span><br>
           EX:<span>7/1</span>購買票券⇒票期:<span>7/1~9/28</span><br>
          ●票券有效期限: 使用第一個設施後，<span>7日內</span>須使用完畢<br>
          EX:<span>7/1</span>開始使用，<span>有效期限至7/7</span><br>
          ●有效期間內可任<span>選3項設施</span><br>
          ●注意: 單次購買複數票劵時，當其中一張票劵開始用，所有票劵也將同步開通 (<span>使用效期7天</span>)<br>
          ●票券開通後，7天的使用效期無法超過票券<span>90天</span>的有效期間。<br>
           EX:票券期限<span>7/1~9/28</span>，若<span>9/27</span>開始使用，其使用效期為<span>9/27~9/28</span><br>
          ●無使用完畢不能兌現<br>
          ●請事先於以下連結確認各設施注意事項・營業時間・公休日情報
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兌換地點' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方網站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hffukuoka/tc/havefun_title_tc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukuoka/CasualDining.png'),
          title: ['休閒居酒屋 雪花'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: [
                '〒810-0003 福岡縣福岡市中央區春吉3-11-19 panorama sukuea博多 1F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yukihana_fuk/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['・店內可用餐時間:約3小時。(僅周末・國定假日前日有限時)'] },
                { cid: 2, text: ['・營業時間請事先至官網確認。'] },
                { cid: 3, text: ['・僅限短期旅遊的外國旅客可使用。'] },
                { cid: 4, text: ['・每人消費2000日圓以上時可使用1000日圓優惠券。'] },
                { cid: 5, text: ['・有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
                ]
            }
          ]
        },
        // 
        // {
        //   id: 2,
        //   img: require('@/assets/images/hffukuoka/KoreanstSeet.png'),
        //   title: ['韓國攤販甜月'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '兌換地點',
        //       content: [{ cid: 1, text: ['福岡市博多區下川端町1-331-2F'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '官方網站',
        //       content: [{ cid: 1, text: ['https://darubamu.owst.jp/zh-tw/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事項',
        //       isDecimal: false, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['・店內可用餐時間:約2小時30分。'] },
        //         { cid: 2, text: ['・營業時間請事先至官網確認。'] },
        //         { cid: 3, text: ['・僅限短期旅遊的外國旅客可使用。'] },
        //         { cid: 4, text: ['・每人消費2000日圓以上時可使用1000日圓優惠券。'] },
        //         { cid: 5, text: ['・有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
        //       ]
        //     }
        //   ]
        // },
        // 
        {
          id: 3,
          img: require('@/assets/images/hffukuoka/HaruyoshiBar.png'),
          title: ['春吉酒吧 Rio'],
          message: [
            {
              msgId: 1,
              title: '兌換地點',
              content: [{ cid: 1, text: ['福岡市中央區春吉2-4-11 Riviere Champ104'] }]
            },
            {
              msgId: 2,
              title: '官方網站',
              content: [{ cid: 1, text: ['https://www.instagram.com/haruyoshi_bar_rio/?igshid=YmMyMTA2M2Y%3D'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['・店內可用餐時間:約2小時。'] },
                { cid: 2, text: ['・營業時間請事先至官網確認。'] },
                { cid: 3, text: ['・僅限短期旅遊的外國旅客可使用。'] },
                { cid: 4, text: ['・每人消費2000日圓以上時可使用。'] },
                { cid: 5, text: ['・有可能會因為店家客滿而無法使用的狀況，請見諒。'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukuoka&l=tc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukuoka-1-week-free-pass/tc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>